import { Button } from "@nextui-org/react";
import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="bg-[url('/public/images/hero.png')] bg-cover bg-center text-textWhite md:h-[90vh] h-[110vh] flex justify-center items-center relative">
      <div className="container md:px-[40px] min-w-full relative z-20">
        <h1 className="sm:text-[48px] text-[36px] font-bold uppercase leading-[55.15px] mb-2.5">
          Travel the world through your screen
        </h1>
        <p className="text-lg sm:text-xl mt-2 text-primary_orange italic">
          Live the experience!
        </p>
        <div className="mt-10 flex lg:items-center gap-4 max-lg:flex-col">
          <Link
            to={"/contact"}
            className="bg-primary_yellow font-medium sm:text-base text-xs text-textColor rounded-[10px] sm:px-12 px-6 py-2 sm:w-fit w-full text-center shadow-mainShadow"
          >
            JOIN OUR COMMUNITY NOW
          </Link>
          <Link
            to={"/travel-awards"}
            className="bg-primary_green font-medium sm:text-base text-xs text-textColor rounded-[10px] sm:px-12 px-6 py-2 sm:w-fit w-full text-center shadow-mainShadow"
          >
            JOIN OUR TRAVEL AWARDS NOW
          </Link>
          <p>1000+ bloggers have joined</p>
        </div>
      </div>
      <div className="absolute left-0 right-0 bottom-0 h-[80%] bg-heroGradiant"></div>
    </section>
  );
};

export default Hero;
