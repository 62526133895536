import React, { useEffect, useState } from "react";
import { blogs } from "../../../../constants/general/blogs";

const PageHeader = ({ blogHeader, articleId }) => {
  const [targetBlog, setTargetBlog] = useState([]);

  useEffect(() => {
    if (articleId) {
      const targetBlog = blogs.filter((item) => item.id === articleId);
      setTargetBlog(targetBlog);
    }
  }, [articleId]);

  return (
    <section
      style={{
        backgroundImage: `url(${blogHeader})`,
      }}
      className={`bg-cover bg-center md:h-[400px] relative h-auto flex items-center justify-center`}
    >
      <div className="container text-textWhite min-w-full md:px-[40px] md:py-0 py-20 relative z-20">
        <h1 className="sm:text-[40px] text-[32px] font-normal xl:w-[90%] leading-[41.36px] uppercase mb-2">
          {targetBlog[0]?.title}
        </h1>
        {targetBlog[0]?.subTitle && (
          <p className="sm:text-xl text-lg leading-[36px]">
            {targetBlog[0]?.subTitle}
          </p>
        )}
        {targetBlog[0]?.writeBy && targetBlog[0]?.editBy ? (
          <div className="flex items-center gap-2.5 md:flex-nowrap flex-wrap mt-5">
            <p className="sm:text-xl text-lg leading-[36px]">
              {targetBlog[0]?.writeBy}
            </p>
            <p className="sm:text-xl text-lg leading-[36px]">
              {targetBlog[0]?.editBy}
            </p>
          </div>
        ) : null}

        {/* <div className="min-[810px]:w-auto w-full flex items-center gap-2.5">
            <span className="sm:text-xl min-[370px]:text-lg text-sm">
              by Joanna Wellick
            </span>
            <div className="min-[370px]:w-5 w-3 h-px bg-textWhite" />
            <Clock />
            <span className="sm:text-xl min-[370px]:text-lg text-sm">
              2 minute read
            </span>
            <div className="min-[810px]:w-5 w-0 h-px bg-textWhite" />
          </div>
          <div className="min-[810px]:w-auto w-full flex items-center gap-2.5">
            <Statistics />
            <span className="sm:text-xl min-[370px]:text-lg text-sm">
              1.6K views
            </span>
            <div className="min-[370px]:w-5 w-3 h-px bg-textWhite" />
            <SocialMedia />
            <span className="sm:text-xl min-[370px]:text-lg text-sm">
              1.2K shares
            </span>
          </div> */}
      </div>
      <div className="absolute left-0 right-0 bottom-0 md:h-[400px] h-auto bg-heroGradiant"></div>
    </section>
  );
};

export default PageHeader;
