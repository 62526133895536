import React from "react";
import Header from "../../general/Header";
import { Image } from "@nextui-org/react";

const WhoIsItFor = () => {
  return (
    <section className="container flex justify-between items-center gap-10 max-lg:flex-col sm:pt-[40px] sm:pb-[80px] pt-[20px] pb-[40px] min-w-full md:px-[40px] mx-0">
      <div className="lg:w-auto w-full">
        <Header title="Who is it for" />
        <h3 className="xl:text-[44px] md:text-[40px] text-[32px] lg:mb-10 mb-5 text-textColor leading-[50.56px]">
          <span className="text-secondaryText">Are you a </span> travel blogger
          ?
        </h3>
        <p className="sm:text-xl text-lg leading-[32px] lg:max-w-[860px] text-textColor">
          Welcome to a thriving community of travel bloggers just like you!
          Here, you’ll find the support, resources, and connections you need to
          turn your travel adventures into a sustainable income. Whether you’re
          a seasoned pro or just starting out, our community is your go-to hub
          for growth and inspiration.
        </p>
      </div>
      <Image
        src="/images/home/whoIsItFor.png"
        classNames={{
          wrapper: "lg:w-[548px] lg:h-[351px] sm:w-3/4 rounded-none",
        }}
        className="rounded-none size-full"
        alt="who is it for"
      />
    </section>
  );
};

export default WhoIsItFor;
