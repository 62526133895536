import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ArticleBody from "../../components/pages/blogs/singleBlog/ArticleBody";
import Comments from "../../components/pages/blogs/singleBlog/Comments";
import PageHeader from "../../components/pages/blogs/singleBlog/PageHeader";
import Shares from "../../components/pages/blogs/singleBlog/Shares";
import YouMayLike from "../../components/pages/blogs/singleBlog/YouMayLike";
import { blogs } from "../../constants/general/blogs";

const SingleBlogPage = () => {
  const { blogName } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [blogName]);

  const filteredBlogs = blogs.filter((article) => article?.title === blogName);

  const blogHeader = `/images/blog/blog${filteredBlogs[0]?.id}header.png`;

  return (
    <div>
      <PageHeader blogHeader={blogHeader} articleId={filteredBlogs[0]?.id} />
      <ArticleBody articleId={filteredBlogs[0]?.id} />
      <Shares />
      <Comments />
      <YouMayLike />
    </div>
  );
};

export default SingleBlogPage;
