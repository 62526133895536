export const blogs = [
  {
    id: 1,
    smTitle:
      "5 Stages of Personal Growth with Adventure Traveler & TEDx Speaker Michelle Spinei ",
    title:
      "5 Stages of Personal Growth with Adventure Traveler & TEDx Speaker Michelle Spinei ",
    author: "Joudy El Rouby",
    subTitle: "TEDxReykjavik",
    writeBy: "Written By: Joudy El Rouby",
    editBy: "Editor & Publisher: Dr. Mahmoud Mansi",
    date: "June 28, 2018",
    background: "/images/blog/blog1.png",
  },
  {
    id: 2,
    smTitle:
      "6 Ways How Traveling Shapes Your Personality: Learnt from TEDx Speaker Gulhan Sen",
    title:
      "6 Ways How Traveling Shapes Your Personality: Learnt from TEDx Speaker Gulhan Sen",
    author: "Yasmine Baddar",
    writeBy: "Written By: Yasmine Baddar",
    editBy: "Editor & Publisher: Dr. Mahmoud Mansi",
    date: "June 28, 2018",
    background: "/images/blog/blog2.png",
  },
  {
    id: 3,
    smTitle:
      "5 Life Changing Insights about “Traveling with No Money” from 7 Times TEDx...",
    title:
      "5 Life Changing Insights about “Traveling with No Money” from 7 Times TEDx Speaker & Musician Edouard Jacqmin",
    author: "Amira Haytham",
    writeBy: "Written by: Amira Haytham",
    editBy: "Editor & Publisher: Dr. Mahmoud Mansi",
    date: "June 28, 2018",
    background: "/images/blog/blog3.png",
  },
  {
    id: 4,
    smTitle:
      "7 Truths Learnt from TEDx Speaker Robin Esrock from his 8-Year Travels",
    title:
      "7 Truths Learnt from TEDx Speaker Robin Esrock from his 8-Year Travels",
    author: "Mariam Shouman",
    subTitle: "TEDxVancouver",
    writeBy: "Written By: Mariam Shouman",
    editBy: "Editor & Publisher: Dr. Mahmoud Mansi",
    date: "June 28, 2018",
    articleAvatar: "/images/authorAvatar.png",
    background: "/images/blog/blog4.png",
  },
];
