import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { Button, Input, Select, SelectItem } from "@nextui-org/react";
import { ArrowRight } from "lucide-react";
import ContactSchema from "../../../schemas/ContactSchema";

const ContactForm = ({ onSubmit }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(ContactSchema),
  });
  console.log(errors);
  return (
    <div className="bg-[url('/public/images/contact/form.png')] bg-cover bg-center mt-8">
      <div className="container md:px-[40px] min-w-full my-6 sm:my-12">
        <div className="bg-white mx-auto max-w-[680px] rounded-[25px] formShadow px-4 sm:px-[110px] py-[60px] sm:py-[90px]">
          <h2 className="sm:text-[48px] text-[32px] text-textColor font-bold text-center leading-[48px]">
            Fill the form and start the journey
          </h2>
          <p className="text-[#8E8E8E] text-center mt-3">
            Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit
            phasellus mollis sit aliquam sit nullam.
          </p>
          <form
            className="flex flex-col gap-6 mt-[40px] sm:mt-[60px]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex gap-2 sm:gap-7 min-[460px]:flex-nowrap flex-wrap">
              <Input
                isInvalid={errors.name}
                errorMessage={errors.name?.message}
                {...register("name")}
                variant="bordered"
                placeholder="Brian Clark"
                label="Name"
                labelPlacement="outside"
                classNames={{
                  base: "min-[460px]:w-auto w-full",
                  inputWrapper: "rounded-[6px] border-[1px] inputShadow",
                  label: "font-bold text-sm",
                }}
              />
              <Input
                isInvalid={errors.phoneNumber}
                errorMessage={errors.phoneNumber?.message}
                {...register("phoneNumber")}
                variant="bordered"
                placeholder="(123) 456 - 7890"
                label="Phone Number"
                labelPlacement="outside"
                classNames={{
                  base: "min-[460px]:w-auto w-full",
                  inputWrapper: "rounded-[6px] border-[1px] inputShadow",
                  label: "font-bold text-sm",
                }}
              />
            </div>
            <Input
              isInvalid={errors.email}
              errorMessage={errors.email?.message}
              {...register("email")}
              variant="bordered"
              placeholder="example@youremail.com"
              label="Email Address"
              labelPlacement="outside"
              classNames={{
                inputWrapper: "rounded-[6px] border-[1px] inputShadow",
                label: "font-bold text-sm",
              }}
            />
            <Controller
              name="travelBloggerType"
              control={control}
              render={({ field }) => (
                <Select
                  isInvalid={errors.travelBloggerType}
                  errorMessage={errors.travelBloggerType?.message}
                  selectedKeys={field.value ? [field.value] : []} // Ensure the value is an array
                  onSelectionChange={(keys) => field.onChange([...keys][0])} // Convert Set to string
                  variant="bordered"
                  label="What type of travel blogger are you?"
                  placeholder="Choose your answer"
                  labelPlacement="outside"
                  classNames={{
                    trigger: "border-[1px] rounded-[6px] inputShadow",
                    label: "font-bold text-sm",
                  }}
                >
                  <SelectItem key="Travel Writer, Journalist or Editor">
                    Travel Writer, Journalist or Editor
                  </SelectItem>
                  <SelectItem key="Travel Photographer">
                    Travel Photographer
                  </SelectItem>
                  <SelectItem key="Travel Vlogger">Travel Vlogger</SelectItem>
                  <SelectItem key="Travel Content Creator">
                    Travel Content Creator
                  </SelectItem>
                </Select>
              )}
            />
            <Controller
              name="tourismType"
              control={control}
              render={({ field }) => (
                <Select
                  isInvalid={errors.tourismType}
                  errorMessage={errors.tourismType?.message}
                  selectedKeys={field.value ? [field.value] : []}
                  onSelectionChange={(keys) => field.onChange([...keys][0])}
                  variant="bordered"
                  label="What type of tourism do you support and specialize in?"
                  placeholder="Choose your answer"
                  labelPlacement="outside"
                  classNames={{
                    trigger: "border-[1px] rounded-[6px] inputShadow mt-6",
                    label: "font-bold text-sm text-left",
                  }}
                >
                  <SelectItem key="Lifestyle & Fashion">
                    Lifestyle & Fashion
                  </SelectItem>
                  <SelectItem key="Destinations & Trends">
                    Destinations & Trends
                  </SelectItem>
                  <SelectItem key="History & Heritage">
                    History & Heritage
                  </SelectItem>
                  <SelectItem key="Culture & Local Stories">
                    Culture & Local Stories
                  </SelectItem>
                  <SelectItem key="Business, Conferences & Entrepreneurship">
                    Business, Conferences & Entrepreneurship
                  </SelectItem>
                  <SelectItem key="Education & Scholarships">
                    Education & Scholarships
                  </SelectItem>
                  <SelectItem key="Philanthropy & Sustainability">
                    Philanthropy & Sustainability
                  </SelectItem>
                  <SelectItem key="Wellness & Healthcare Tourism">
                    Wellness & Healthcare Tourism
                  </SelectItem>
                  <SelectItem key="Spiritual & Religious Tourism">
                    Spiritual & Religious Tourism
                  </SelectItem>
                  <SelectItem key="Adventure travel">
                    Adventure travel
                  </SelectItem>
                  <SelectItem key="Sports travel">Sports travel</SelectItem>
                  <SelectItem key="Foodies">Foodies</SelectItem>
                </Select>
              )}
            />
            <Controller
              name="travelCompanions"
              control={control}
              render={({ field }) => (
                <Select
                  isInvalid={errors.travelCompanions}
                  errorMessage={errors.travelCompanions?.message}
                  selectedKeys={field.value ? [field.value] : []}
                  onSelectionChange={(keys) => field.onChange([...keys][0])}
                  variant="bordered"
                  label="Who are your travel companions?"
                  placeholder="Choose your answer"
                  labelPlacement="outside"
                  classNames={{
                    trigger: "border-[1px] rounded-[6px] inputShadow",
                    label: "font-bold text-sm",
                  }}
                >
                  <SelectItem key="Solo traveler">Solo traveler</SelectItem>
                  <SelectItem key="Family travel">Family travel</SelectItem>
                  <SelectItem key="Couples travel">Couples travel</SelectItem>
                  <SelectItem key="Friends & group travel">
                    Friends & group travel
                  </SelectItem>
                  <SelectItem key="Pet travel">Pet travel</SelectItem>
                </Select>
              )}
            />
            <Controller
              name="audienceNeeds"
              control={control}
              render={({ field }) => (
                <Select
                  isInvalid={errors.audienceNeeds}
                  errorMessage={errors.audienceNeeds?.message}
                  selectedKeys={field.value ? [field.value] : []}
                  onSelectionChange={(keys) => field.onChange([...keys][0])}
                  variant="bordered"
                  label="Which of the following best fits your audience’s needs and blogging strategy?"
                  placeholder="Choose your answer"
                  labelPlacement="outside"
                  classNames={{
                    trigger: "border-[1px] rounded-[6px] inputShadow mt-6",
                    label: "font-bold text-sm text-left",
                  }}
                >
                  <SelectItem key="Luxury travel">Luxury travel</SelectItem>
                  <SelectItem key="Low budget travel">
                    Low budget travel
                  </SelectItem>
                </Select>
              )}
            />
            <Controller
              name="contentType"
              control={control}
              render={({ field }) => (
                <Select
                  isInvalid={errors.contentType}
                  errorMessage={errors.contentType?.message}
                  selectedKeys={field.value ? [field.value] : []}
                  onSelectionChange={(keys) => field.onChange([...keys][0])}
                  variant="bordered"
                  label="What type of content do you provide?"
                  placeholder="Choose your answer"
                  labelPlacement="outside"
                  classNames={{
                    trigger: "border-[1px] rounded-[6px] inputShadow mt-6",
                    label: "font-bold text-sm text-left",
                  }}
                >
                  <SelectItem key="City">City</SelectItem>
                  <SelectItem key="Nature">Nature</SelectItem>
                  <SelectItem key="Experiential travel">
                    Experiential travel
                  </SelectItem>
                  <SelectItem key="Festival & events travel">
                    Festival & events travel
                  </SelectItem>
                  <SelectItem key="Backpacking">Backpacking</SelectItem>
                </Select>
              )}
            />

            <Input
              isInvalid={errors.socialMediaLink}
              errorMessage={errors.socialMediaLink?.message}
              {...register("socialMediaLink")}
              variant="bordered"
              placeholder="Write your answer here .."
              label="Social media link for your most used platform"
              labelPlacement="outside"
              classNames={{
                inputWrapper: "rounded-[6px] border-[1px] inputShadow",
                label: "font-bold text-sm",
              }}
            />
            <Button
              type="submit"
              radius="sm"
              className="flex items-center gap-2 text-sm text-white bg-black"
            >
              Send message <ArrowRight size={15} />
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
