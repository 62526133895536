import React from "react";
import Header from "../../general/Header";

const LearnAboutUs = () => {
  return (
    <div className="container sm:pb-[60px] pb-[30px] sm:pt-[40px] pt-[20px] min-w-full md:px-[40px] mx-0">
      <Header title="Learn About Us" />
      <h3 className="sm:text-[44px] text-[32px] text-secondaryText font-normal">
        Join the first{" "}
        <span className="text-black">Travel Bloggers Award Community</span> to
        connect with fellow travel content creators, celebrate outstanding
        travel stories, and gain visibility. Whether you’re a seasoned blogger
        or just starting, this is your chance to be part of an exciting new
        movement in the travel industry!
      </h3>
    </div>
  );
};

export default LearnAboutUs;
