import { Image } from "@nextui-org/react";
import React from "react";
import { Link } from "react-router-dom";

const ArticleCard = ({ article, page }) => {
  return (
    <div
      className={`shadow-mainShadow flex flex-col h-full ${
        page === "blogs"
          ? "w-full"
          : "xl:w-[calc(50%-20px)] lg:w-[calc(50%-80px)] md:w-[calc(50%-20px)] min-[540px]:w-[75%]"
      }`}
    >
      <div className="relative">
        <Image
          classNames={{ wrapper: "size-full rounded-none min-h-[281px]" }}
          className="rounded-none size-full"
          src={article.background}
          alt={article.title}
        />
      </div>
      <div className="px-4 flex flex-col flex-grow">
        <div className="flex-grow">
          <h3 className="text-secondaryBlack sm:text-2xl text-lg leading-[40px] mt-3">
            <Link to={`/travel-blogs/blog/${article.title}`}>
              {article.smTitle}
            </Link>
          </h3>
          <div className="flex items-center gap-2 my-4">
            <div className="flex items-center gap-1">
              <div className="w-4 h-4 rounded-full bg-[#D9D9D9]" />
              <p className="text-secondaryBlack leading-[40px] sm:text-base text-sm">
                {article.author}
              </p>
            </div>
            <div className="min-[390px]:w-9 w-4 h-px bg-secondaryGray opacity-40" />
            <p className="text-sm text-secondaryGray">{article.date}</p>
          </div>
        </div>
        <Link
          to={`/travel-blogs/blog/${article.title}`}
          className="text-secondaryBlack underline underline-offset-[12px] block mb-8 leading-[20.96px]"
        >
          View Post
        </Link>
      </div>
    </div>
  );
};

export default ArticleCard;
