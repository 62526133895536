import React from "react";
import { blogs } from "../../../../constants/general/blogs";
import ArticleCard from "../../../general/ArticleCard";
import { useParams } from "react-router-dom";

const YouMayLike = () => {
  const { blogName } = useParams();

  const filteredBlogs = blogs.filter((article) => article.title !== blogName);

  return (
    <div className="container min-w-full md:px-[40px] md:mb-[80px] mb-[40px]">
      <h2 className="uppercase sm:text-2xl text-xl font-normal text-textColor my-6">
        You may also like:
      </h2>
      <div className="grid xl:grid-cols-3 md:grid-cols-2 lg:gap-10 md:gap-5 gap-10 xl:w-full lg:w-[90%] md:w-full min-[460px]:w-[80%] xl:mx-0 mx-auto">
        {filteredBlogs.slice(0, 3).map((article) => (
          <ArticleCard page={"blogs"} key={article.id} article={article} />
        ))}
      </div>
    </div>
  );
};

export default YouMayLike;
