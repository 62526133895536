import { Image } from "@nextui-org/react";
import React from "react";
import { Link } from "react-router-dom";

const ArticleBody = ({ articleId }) => {
  const blog4Items = [
    {
      title:
        "1-Anyone who has traveled for a long time, they are either running away from something, or looking for something!",
      desc: "When you start traveling you should know your purpose of the journey. Maybe you are running away from your own life, memories and mistakes. Or looking for something, thinking about the life you weren’t living, the places you weren’t seeing and the people you weren’t meeting. When you figure out why do you want to travel, take the risk and start your journey.",
    },
    {
      title:
        "2-Whatever decision you make is the best decision you could possibly make!",
      desc: "Our lives are mitigated by routine. When you start your traveling journey decision-making begins to take on a new significance. Every morning you think where are you going to eat, sleep or find a clean toilet? Once these basic life needs were met you start facing a deeper, familiar crises. You begin questioning yourself and your decisions smelling that greener grass. When there are so many decisions to make sometimes it’s just easier not to make any. Esrock stated that “you feel that acuity when you travel independently and you feel that acuity when you don’t, this is called a decision paralysis”. To overcome this crisis, you should definitely know that wherever you are is where you are supposed to be. Once you truly understand that decision making will be a lot easier.",
    },
    {
      title: "3-People would rather help you than hurt you!",
      desc: "Esrock believes that travelers become victims when they look like victims. Some people think that the moment they get off the plane they will be kidnapped, threatened or even killed especially while traveling to developing countries where all you hear is bad news. As Esrock stated “If it bleeds it leads”. From Esrock’s personal experience after traveling to a 107-countries on 6 continents without being robbed or attacked he concluded that either he is extremely lucky or as he likes to believe as one of the vast majority of travelers. From his personal experience, Albania is the friendliest country in the world. Finally, he understood that locals take pride in showing the traveler their world, their untold stories and their culture. Of course there are some places that we shouldn’t go and explore although he believes that travelers are seldom targeted, locals might want to kill each other but they don’t go after tourists for anything other than petty crime which can be dismissed with the basic amount of common sense.",
    },
    {
      title: "4- Smile when you’re nervous and laugh when you are scared!",
      desc: "Definitely people will attack if you provoke them, and everywhere you will find good people and bad ones but the weapon you should always have with you is your smile. There’s something about smiling that projects warmth and honesty. As Esrock believes “ It’s like a softening of the soul” that’s why people love travelling in places like Africa and India even though it can be very challenging. The locals and the people you meet there disarm you with their warmth and friendliness. Finally, Esrock advises us to smile when we’re nervous and laugh when we are scared because it sure beats screaming and crying.",
    },
    {
      title: "5-Trust your guts!",
      desc: "Esrok believes that the more we use our instinct, the more we hear it, the more we use it and the more chance we have to avoid whatever it is that’s trying to save us from. Train yourself to listen to your instinct and follow your heart it always knows the way.",
    },
    {
      title: "6-People you meet create the paradise you find!",
      desc: "It’s all about the people and the paradise, literally. Paradise islands, epic festivals and wild parties, it doesn’t mean much if you aren’t connecting and not sharing with the right people. Simply because, people we meet on a journey out there shape the world. They shape the way we look at everything. They make you want to get off the couch, switch off reality TV, and start participating in reality itself.",
      secondaryDesc:
        "As much as Esrock traveled around the world, it’s his friends mean the world to him. They take him in places he’d never thought he’d get to. The more you travel, the more people you will meet and the more friends you will have.",
    },
    {
      title: "7-Don’t panic!",
      desc: "These two words have helped him when he really needed them like when he jumped off the Sky Tower in Monaco. They were there for him when he came to face with death deep in an ancient burial cave in the South Pacific, and when he came out there in front of 2000 people at the largest TEDx ever held anywhere. Don’t panic, surround yourself with good people, take risks, enjoy the moment and live.",
      secondaryDesc:
        "Esrock states that he has been fortunate to chase his passion to a degree of absurdity. He has run around the world ticking of one bucket list after another and he believes that his success in a very competitive industry like travel media is due to applying these truths that he has learned out there into his daily bizarre world. The lessons that he has learned from his travels keep applying to the world he lives in his daily life because he doesn’t believe that we ever stop travelling and we never stop growing So when you decide to start your own journey make sure you pack the right state of mind, smile, listen to your guts, experience new things, dive into new cultures, listen to the unspoken stories, get to know new people and most importantly live each moment of your journey as if it’s the last one.",
    },
    {
      heading: "More About the Speaker:",
      ternaryDesc: (
        <>
          <Link
            to="https://www.robinesrock.com/"
            target="_blank"
            className="text-linkColor me-1 underline"
          >
            Robin Esrock
          </Link>
          (
          <Link
            to="https://www.instagram.com/robinesrock/"
            target="_blank"
            className="text-linkColor underline"
          >
            Instagram
          </Link>
          ) created and hosted a travel show called
          <Link
            to="http://www.wordtravels.tv/"
            target="_blank"
            className="text-linkColor underline mx-1"
          >
            World Travels
          </Link>
          filmed in 36 countries and in 21 languages. His journey Modern
          <Link
            to="https://en.wikipedia.org/wiki/Gonzo_journalism"
            target="_blank"
            className="text-linkColor underline mx-1"
          >
            Gonzo
          </Link>
          started when he had a bike
          <Link
            to="https://vancouversun.com/travel/international-travel/meet-robin-esrock-author-of-the-great-canadian-bucket-list"
            target="_blank"
            className="text-linkColor underline mx-1"
          >
            accident
          </Link>
          in 2004. Struck down on his bike at a Vancouver intersection and broke
          his kneecap. This accident was a
          <Link
            to="https://www.canadianbucketlist.com/about-robin-esrock/#google_vignette"
            target="_blank"
            className="text-linkColor underline mx-1"
          >
            turning point
          </Link>
          in his life. A year later, he received a 20,000 dollars insurance
          settlement and decided to quit his job, pack up his things and set off
          on a one-year solo round-the-world backpacking adventure to 24
          countries before giving his TEDx speech. Now he has travelled more
          than 110 countries with recorded experiences.
        </>
      ),
    },
  ];

  const blog2Items = [
    {
      title: "1. An Expanded Horizon",
      desc: "Traveling hazes non-existent boundaries between countries, letting you see beyond inherited prejudices and biases that we primarily absorb throughout our childhood without ever thinking “why?”. “We’re more alike than different,” she reflects; that no matter your race, religion, or nationality, we all vitally seek love, peacefulness, and happiness.",
    },
    {
      title: "2. Flexibility and Problem-solving",
      desc: "Each trip into the unknown builds the ability to “recoil”, by planting you into unexpected obstacles through your travel. “Every little challenge abroad feels like a victory.” Who wouldn’t want the satisfaction that comes after passing something you thought is the end of the world anyway?",
    },
    {
      title: "3. Humility",
      desc: "Standing amidst ancient wonders or vast landscapes that are not familiar to your eye and nothing like your surroundings, reminds you how huge the world is, helping you feel anchored yet sprinkled with fairy dust all over. “You’re a part of something so much bigger” and there is nothing more comforting than feeling that despite the immensity of the world, we’re only oxford commas in an encyclopedia.",
    },
    {
      title: "4. Self-Reflection and Growth",
      desc: "With cultural differences considered, it will certainly place you in unimaginable situations, and how you closely respond to them, loosening their knots will let you discover your fingers, “in every journey, you meet pieces of your soul.”",
    },
    {
      title: "5. Silent Connection",
      desc: "There’s a whole other underrated language that is universally spoken- kindness and empathy. Building “silent” bonds with people who don’t speak your language would teach you the unteachable, as no matter the language, “a smile goes a long way”.",
    },
    {
      title: "6. A Sense of Belonging Anywhere and Everywhere",
      desc: "Through her experiences, she found that each place adds to a sense of global belonging, a “home everywhere” and that she belongs to a global family.",
    },
    {
      desc: "Travel isn’t just about crossing borders—it’s about expanding the limitless mind and soul. Gülhan Şen’s insights remind us that every journey, whether across continents or into unfamiliar cultures, comes with it a priceless opportunity for growth and transformation. From shedding old preconceptions and embracing new perspectives, to wearing resilience as a shield in the face of challenges and discovering a deep sense of connection with people around the world. Ultimately, travel shapes us into more empathetic, self-reflective, and globally-minded individuals, teaching us that no matter where we go, we can always find a home in the people we meet and the experiences we embrace.",
    },
  ];

  const blog1Items = [
    {
      title: "1. Feeling Discomfort",
      desc: " Discomfort is a common initial response to a new place or unfamiliar person, much like in daily life. It’s natural to feel unsettled during travel, but these feelings often mirror those we encounter back home. Michelle describes the situation like “hiking on the moon” which could give many different meanings and mixed emotions. Waking there would feel like the world is flying alongside you, however the cold idea that you are out of your planet and beyond your comfort zone causes this positive discomfort. Although “walking on the moon” and out of your plant may make you feel alienated, yet it shall make you more human as “it tests your strength and endurance”.",
    },
    {
      title: "2. Facing Challenges",
      desc: "No matter how well you plan, challenges are inevitable—whether it’s a blocked trail, unexpected weather, or physical discomfort like sore feet. These obstacles can trigger anxiety and fear of the unknown, but they’re part of the adventure. Michelle stated that “the landscape looks better after going through some conflict” or perhaps “the landscape allows us to face our internal conflicts.” Because of a conversation she had with a group of fellow hikers that sparked from complaining about the trail and physical feelings then it turned into deep developed conversations about life and how people are struggling and using the adventure to get out of stressful situations by triggering new situations that clear the mind, leaving you on the surface of our “moon” witnessing one of the best human feelings ever, which is accomplishment.",
    },
    {
      title: "3. Taking Risks",
      desc: "Going on an adventure trip is the last form of socially acceptable risk taking. However, numerous people don’t realize how crucial these quick reflexes are. For instance, changing the route when the trail is blocked or pause for rest if they have sore feet. In Michelle’s TEDx Talk she stated that, “we live in a risk-averse society” which means that from a very young age children are told to stay safe and never come close to danger, but as soon as they become adults, simple decisions could be haunting. As a result a weak indecisive person will grow, a simple way to solve this is by going on an adventure travel, and learning how to wisely and safely deal with any dangers or hazards, and make split second decisions that will blossom your mind into a complex of higher intellectual and emotional intelligence.",
    },
    {
      title: "4. Connecting with Others",
      desc: "On the trail to your destination, you could possibly meet with other fellow travelers, different hikers, each hiking for a different quest, yet surprisingly similar. Firstly, the conversations could start with casual discussions then it evolves to deeper conversations unearthing confessions and opinions about life, work, relationships or personal worries, where the journey itself allows you to free your own personal conflicts to a fellow hiker that might be an absolute stranger and to the wilderness. These types of conversations along the adventure will cleanse your past and shape your future. And, these types of relationships will have a spiritual bond that might last for a lifetime.",
    },
    {
      title: "5. Reaching the Destination",
      desc: "After an exhausting journey to your destination, you will finally realize that everything is exceedingly different as a new and unique perspective arises in your mind. Instead of feeling a huge balloon of relief filling your chest after having ginormous conflicts to reach your desired place, you’ll realize that the famous quote of “success is a journey not a destination” is so true, as it sinks deeper into your mind. As this will prove and emphasize how simple life truly is.",
      secondaryDesc:
        "Soon enough you will land on “Earth”, return back home, with a sharper perspective, a brighter mind and heart, while being less anxious and quicker thinking with better problem-solving skills. As small as these new skills and improvements are, as mighty as they could save an entire society, whether through a TEDx Talk, a book, a movie, or simply through touching the hearts and minds of others with your unique perspectives and adventurous stories.",
    },
    {
      heading: "More About our Traveler and TEDx Speaker:",
      ternaryDesc: (
        <>
          <Link
            to="https://www.linkedin.com/in/michelle-spinei-b59bb933/?originalSubdomain=is"
            target="_blank"
            className="text-linkColor me-1 underline"
          >
            Michelle Spinei
          </Link>
          is a travel influencer in so many distinctive ways. She is a travel
          entrepreneur where she co-founded an online platform named
          <Link
            to="https://www.instagram.com/koride.co/?hl=en"
            target="_blank"
            className="text-linkColor mx-1 underline"
          >
            Koride
          </Link>
          for connecting travelers. Michelle is also a travel writer with
          various publications in several travel magazines with an interesting
          writing piece about
          <Link
            to="https://www.fodors.com/news/news/uh-oh-the-travel-influencers-are-back-this-is-what-id-like-to-tell-them"
            target="_blank"
            className="text-linkColor mx-1 underline"
          >
            travel influencers
          </Link>
          and
          <Link
            to="https://vacayou.com/magazine/sky-lagoon/"
            target="_blank"
            className="text-linkColor ms-1 underline"
          >
            other
          </Link>
          <Link
            to="https://matadornetwork.com/author/michellespinei/"
            target="_blank"
            className="text-linkColor mx-1 underline"
          >
            topics
          </Link>
          , in addition to being an academic
          <Link
            to="https://www.researchgate.net/profile/Michelle-Spinei"
            target="_blank"
            className="text-linkColor mx-1 underline"
          >
            researcher
          </Link>
          specialized in tourism with a Master of Science degree in Natural
          Resource Recreation and Tourism from the
          <Link
            to="https://english.hi.is/university_of_iceland"
            target="_blank"
            className="text-linkColor ms-1 underline"
          >
            University of Iceland
          </Link>
          , and has been working in the travel industry across several
          organizations including the
          <Link
            to="https://www.tripadvisor.com/"
            target="_blank"
            className="text-linkColor ms-1 underline"
          >
            TripAdvisor
          </Link>
          . Currently she is the
          <Link
            to="https://theorg.com/org/iceland-innovation-week/org-chart/michelle-spinei"
            target="_blank"
            className="text-linkColor mx-1 underline"
          >
            International Relations Manager
          </Link>
          of
          <Link
            to="https://www.innovationweek.is/"
            target="_blank"
            className="text-linkColor ms-1 underline"
          >
            Iceland Innovation Week
          </Link>
          .
        </>
      ),
    },
  ];

  const blog3Items = [
    {
      title: "Hitchhiking Where You Only Have One Option",
      desc: "For 21 days, Eduoard had no money for transportation, so he hitchhiked his way through europe. To no one's surprise, he discovered that not a lot of people like hitchhikers, and many people refused to give him a ride just because they were mad at the concept itself. But he also met a lot of kind drivers who did offer him help, and one of the most remarkable experiences was when a Ukrainian man offered him a ride in a very dirty truck. Eduoard sat with the ukrainian man for 2 hours laughing and nodding while the man talked gibberish. After that day, Eduoard reflected on how he had no choice but to ride with the Ukrainian in the dirty truck, how he didn't have the luxury of choosing a better looking ride, and how despite his initial disgust, it was one of the funniest rides of his life.",
    },
    {
      title: "Begging for Accomodation",
      desc: "What would you say if a stranger came up to you in the street and asked, “can I spend the night at your house?” Personally, I’d admire the courage it took to ask such a question. Eduoard went up to many strangers with that odd request, many replied with a furrowed face and a confused ‘no’. However, in the 21 days Eduoard always had a roof above his head. Facing the fear of embarrassment and rejection is something most of us cannot handle, and at the risk of sleeping on the street, Eduoard faced the fear with no shame. And surprisingly, people offering help aren’t murderers or thieves, they’re kind and full of hospitality.",
    },
    {
      title: "Rejection is Essential",
      desc: "From drivers on the road and strangers on the street, Edouard also approached workers at restaurants, asking for leftovers or perhaps free food. Some agreed, some yelled at him and some simply ignored him, but the rejection shaped Eduoard’s mind in a way he appreciated. Whenever he received a ‘no’, he didn’t find himself thinking of the worst-case scenarios, but he thought, “I’ll find something else.” He highlights how important it is for us to handle rejection, and see it as a blessing in disguise instead of a crisis waiting to happen.",
    },
    {
      title: "Is Codependency Really A Bad Thing?",
      desc: "Money is freedom and independence, this is what we’re taught our whole lives, and while it is a fact no one denies, is it always a good thing? Edouard depended on strangers for 21 days to feed him, drive him around and put a roof over his head. At the beginning of his journey, when he emptied his wallet and took off, he had one question on his mind: What can I get from this person? Food? A house? Perhaps he’ll be kind enough to provide cash. Edouard’s question changed to “What can I give this person? A smile? A talk? A memorable moment?” He learnt that codependency showed him that connections and experiences with people are priceless.",
    },
    {
      title: "Should you try it out?",
      desc: "The short answer is no, but sometimes answers aren’t as simple as the questions we ask. On his trip, the lesson wasn’t to learn how important money is, but how it blinds us from facing problems and experiences needed for our growth as human beings. Eduoard jokes about how he wouldn’t tell anyone to leave their wallet at home and go on a venture across Europe, but he leaves his listening crowd touched by his story, and questioning how money shaped their own lives.",
    },
    {
      title: "More About our Traveler and TEDx Speaker:",
      desc: (
        <>
          <Link
            to="https://www.youtube.com/channel/UC4mSZ1zvKyYv2v1_6JJJKhw"
            target="_blank"
            className="text-linkColor underline"
          >
            Edouard Jacqmin
          </Link>
          , a 29-year-old composer, songwriter, and producer from Belgium, has
          been making waves in the music scene since graduating from
          <Link
            to="https://www.mdx.ac.uk/"
            target="_blank"
            className="text-linkColor mx-1 underline"
          >
            Middlesex University of London
          </Link>
          with a degree in music composition in 2015. Over the past year and a
          half, he’s been actively releasing music and performing. In June 2016,
          he embarked on a daring 21-day adventure across Eastern Europe,
          traveling without a cent to his name. Armed with only his guitar and a
          bold spirit, he hitchhiked and asked strangers, “Can I stay at your
          place tonight?”. This unique experience led to the creation of his
          debut EP, Happily, which was released in November 2016 and features
          songs inspired by Slovakia, the Czech Republic, and Hungary. Now based
          in Brussels, Edouard is focused on crafting his first
          <Link
            to="https://soundcloud.com/edouard_j"
            target="_blank"
            className="text-linkColor ms-1 underline"
          >
            full-length album
          </Link>
          .
        </>
      ),
    },
  ];

  return (
    <section className="bg-[url('/public/images/blog/single-blog-bg.png')] bg-cover">
      <div className="container min-w-full lg:px-[100px] md:px-[40px] md:pt-[40px] pt-[20px] md:pb-[70px] pb-[30px]">
        {articleId === 4 && (
          <div>
            <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-1">
              Imagine stepping into a vibrant tapestry of cultures, where every
              corner tells a new story and every experience sparks curiosity.
              When you travel you experience a whole new world extremely
              different from yours. Travelling abroad is like diving into the
              unknown, diving into a world full of mysteries and adventures.
              Travelling abroad reshapes your personality where you start to see
              the world from a new perspective. In this article, we will delve
              into the transformative power of educational travel, highlighting
              its benefits and sharing some lessons to learn before embarking
              your travel journey.
            </p>
            <p className="sm:text-lg text-secondaryBlack leading-[33px] sm:mb-10 mb-5">
              On the
              <Link
                to={"https://www.ted.com/tedx/events/6682"}
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                21st of October
              </Link>
              2012 on the stage of
              <Link
                to={
                  "https://www.linkedin.com/company/tedxwhistler/?originalSubdomain=ca"
                }
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                TEDxVancouver
              </Link>
              in
              <Link
                to={"https://www.canada.ca/en.html"}
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                Canada
              </Link>
              ,
              <Link
                to={"https://en.wikipedia.org/wiki/Robin_Esrock"}
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                Robin Esrock
              </Link>
              – global adventurer, travel writer and T.V. producer – presented a
              marvelous TEDx Talk entitled “
              <Link
                to={"https://www.youtube.com/watch?v=S0TemlxiMdw"}
                target="_blank"
                className="text-linkColor underline"
              >
                Learn to Travel - Travel to Learn
              </Link>
              ” reaching 1.1 Million views today and still receiving comments
              after 11 years of publication, one of them was from YouTube user
              @a_p3242 in 2024: “Over a decade old and still relevant today”. So
              what makes this trendy talk so special and on demand for more than
              11 years?
            </p>
            <iframe
              className="mx-auto my-4 lg:w-[90%] w-full lg:h-[673px] md:h-[473px] h-[273px] rounded-[10px]"
              src="https://www.youtube.com/embed/S0TemlxiMdw"
              title="Learn to travel -- travel to learn: Robin Esrock at TEDxVancouver"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        )}

        {articleId === 1 && (
          <div>
            <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-1">
              Have you ever wondered why people return from travel with new
              perspectives? Their journeys vary greatly. A relaxing beach
              vacation offers safe transportation and the joy of unwinding.
              Others travel for work, to visit family, for research, cultural
              experiences, education, shopping, or adventure. Adventure travel,
              in particular, can be exhilarating and exhausting—but with this
              comes all the gems! It’s one of the few types of travel that can
              change you 180 degrees.
            </p>
            <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-1">
              On the
              <Link
                to={"https://www.ted.com/tedx/events/35285"}
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                13th of October 2019
              </Link>
              on the stage of
              <Link
                to={"http://www.tedxreykjavik.is/home"}
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                TEDxReykjavik
              </Link>
              in
              <Link
                to={"https://en.wikipedia.org/wiki/Reykjav%C3%ADk"}
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                Reykjavik
              </Link>
              the beautiful capital of
              <Link
                to={"https://www.visiticeland.com/"}
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                Iceland
              </Link>
              , Michelle Spinei – a travel expert in the Arctic region and
              Nordic culture – explores how adventure travel brings about
              discomfort and risk. In her TEDx Talk “
              <Link
                to={
                  "https://www.ted.com/talks/michelle_spinei_can_adventure_travel_change_you?subtitle=en"
                }
                target="_blank"
                className="text-linkColor  underline"
              >
                Can Adventure Travel Change You?
              </Link>
              ” Spinei explains that through these rough experiences, travelers
              can gain new perspectives in their everyday lives. Her insights
              stem from her 2017 research in Iceland during a surge in adventure
              tourism with an interesting group of hikers.
            </p>
            <p className="sm:text-lg text-secondaryBlack leading-[33px] sm:mb-10 mb-5">
              Here are Spinei’s 5 stages of change and personal growth through
              adventure traveling:-
            </p>
            <iframe
              className="mx-auto my-4 lg:w-[90%] w-full lg:h-[673px] md:h-[473px] h-[273px] rounded-[10px]"
              src="https://www.youtube.com/embed/Sv1B0IonzqM"
              title="Can adventure travel change you? | Michelle Spinei | TEDxReykjavik"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        )}

        {articleId === 2 && (
          <div>
            <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-1">
              Travelling sure does drain wallets, but only to furtherly enrich
              so much more, and that is what
              <Link
                to={"https://en.wikipedia.org/wiki/G%C3%BClhan_%C5%9Een"}
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                Gülhan Şen
              </Link>
              explored through her unique view on travelling in her
              <Link
                to={"https://www.youtube.com/watch?v=Ofj5YRS2K7w"}
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                TEDx Talk
              </Link>
              conducted in 2014. She is a Bulgarian-Turkish T.V. presenter with
              her famous travel show “
              <Link
                to={"https://www.imdb.com/title/tt1887045/"}
                target="_blank"
                className="text-linkColor underline"
              >
                Gülhan’ın Galaksi Rehberi
              </Link>
              ”, and she is also global
              <Link
                to={"https://www.instagram.com/gulhan_sen/?hl=en"}
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                travel blogger
              </Link>
              who has been to more than 100 countries and 350 cities over the
              span of 7 years.
            </p>
            <p className="sm:text-lg text-secondaryBlack leading-[33px] sm:mb-10 mb-5">
              Travelling always connotes unwanted expenses, a chill vacay in a
              luxurious 5-star hotel, which basically negates the fact that
              travelling may actually contribute to making you feel “whole” and
              just closer to yourself, and that is exactly what Şen sheds light
              on.
            </p>
            <iframe
              className="mx-auto my-4 lg:w-[90%] w-full lg:h-[673px] md:h-[473px] h-[273px] rounded-[10px]"
              src="https://www.youtube.com/embed/Ofj5YRS2K7w"
              title="How traveling shapes your personality: Gulhan Sen at TEDxFatihUniversity"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        )}

        {articleId === 3 && (
          <div className="sm:mb-10 mb-5">
            <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-2.5">
              Seven years ago
              <Link
                to="https://www.youtube.com/channel/UC4mSZ1zvKyYv2v1_6JJJKhw"
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                Edouard Jacqmin
              </Link>
              - a Belgian composer, songwriter and passionate traveler - decided
              to go on a lifetime challenge of having a 21-day Euro trip with no
              money or internet, perhaps for the sake of adventure, discovery,
              or seeking inspiration to launch a new album. After this life
              changing challenge, Edouard achieved another milestone by giving 7
              TEDx Talks in a 4 month duration in 6 different countries about “
              <Link
                to="https://www.ted.com/talks/edouard_jacqmin_traveling_with_no_money?subtitle=en"
                target="_blank"
                className="text-linkColor underline"
              >
                Traveling with No Money
              </Link>
              ” narrating his travel adventures that are full of travel tips and
              profound life lessons.
            </p>
            <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-2.5">
              Jacqmin’s public speaking quest started in early 2017, when he
              embarked on an inspiring cultural tour across the stages of
              Europe. His story-telling journey then began on the
              <Link
                to="https://www.ted.com/tedx/events/17711"
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                4th of February
              </Link>
              in France at
              <Link
                to="https://www.youtube.com/watch?v=ykPgNpUNvrM"
                target="_blank"
                className="text-linkColor ms-1 underline"
              >
                TEDxSciencesPoCampusMenton
              </Link>
              , where he captivated the audience under the Mediterranean sun. On
              the
              <Link
                to="https://www.ted.com/tedx/events/19961"
                target="_blank"
                className="text-linkColor ms-1 underline"
              >
                11th of March
              </Link>
              , he continued to the
              <Link
                to="https://www.netherlands-tourism.com/"
                target="_blank"
                className="text-linkColor ms-1 underline"
              >
                Netherlands
              </Link>
              , where the snow began to melt, delivering his second talk at
              <Link
                to="https://www.youtube.com/watch?v=LbS46jItxWY"
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                TEDxYouth@Maastricht
              </Link>
              amidst the city’s charming canals. By the
              <Link
                to="https://www.ted.com/tedx/events/20191"
                target="_blank"
                className="text-linkColor ms-1 underline"
              >
                19th of March
              </Link>
              , he found himself in the historic city of
              <Link
                to="https://en.wikipedia.org/wiki/Zaragoza"
                target="_blank"
                className="text-linkColor ms-1 underline"
              >
                Zaragoza
              </Link>
              ,
              <Link
                to="https://www.spain.info/en/"
                target="_blank"
                className="text-linkColor ms-1 underline"
              >
                Spain
              </Link>
              , sharing his ideas at
              <Link
                to="https://www.youtube.com/watch?v=MnmYI9fiEbI"
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                TEDxZaragoza
              </Link>
              against a backdrop of Moorish and Gothic influences. Returning to
              France on
              <Link
                to="https://www.ted.com/tedx/events/20648"
                target="_blank"
                className="text-linkColor ms-1 underline"
              >
                2nd of May
              </Link>
              , he spoke at
              <Link
                to="https://www.youtube.com/watch?v=qXjped_8aIY"
                target="_blank"
                className="text-linkColor ms-1 underline"
              >
                TEDxArtsEtMétiersAngers
              </Link>
              , blending his message with the region’s rich history. A few days
              later, on
              <Link
                to="https://www.ted.com/tedx/events/20161"
                target="_blank"
                className="text-linkColor ms-1 underline"
              >
                6th of May
              </Link>
              , he engaged the audience at
              <Link
                to="https://www.youtube.com/watch?v=2oYgDgoHfWk"
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                TEDxTechnicalUniversityofCrete
              </Link>
              in Greece, surrounded by the island’s ancient beauty. On
              <Link
                to="https://www.ted.com/tedx/events/20690"
                target="_blank"
                className="text-linkColor ms-1 underline"
              >
                9th of May
              </Link>
              , he addressed his home country of Belgium at
              <Link
                to="https://www.youtube.com/watch?v=tlGNJc1_sBo"
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                TEDxUCLouvain
              </Link>
              where academic spirit met his vision. His European adventure
              concluded on
              <Link
                to="https://www.ted.com/tedx/events/21649"
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                13th of May
              </Link>
              in the serene
              <Link
                to="https://www.visitengland.com/"
                target="_blank"
                className="text-linkColor mx-1 underline"
              >
                English
              </Link>
              countryside at
              <Link
                to="https://www.youtube.com/watch?v=J5XdCL_ROvo"
                target="_blank"
                className="text-linkColor ms-1 underline"
              >
                TEDxUniversityofKent
              </Link>
              . Across continents and cultures, his talks left a trail of
              inspiration and connection.
            </p>
            <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-2.5">
              In his TEDx talks he announced that the first thing he chose to do
              before going on his journey from Germany to Hungary is “empty his
              wallet”. To answer the questions roaming in your mind; is it
              realistic, is it an experience you should try, and what was the
              point?
            </p>
            <h4 className="sm:text-2xl text-lg text-secondaryBlack leading-[33px] font-medium">
              Here’s how he made it happen:
            </h4>
          </div>
        )}

        {articleId === 4 && (
          <h2 className="uppercase font-medium sm:text-[48px] md:text-[36px] text-xl leading-[55.15px] sm:mb-10 mb-5">
            These are some truths he shared with us from his 8-year traveling
            experience:-
          </h2>
        )}

        {articleId === 2
          ? blog2Items.map((item, idx) => (
              <div key={idx}>
                {item.title && (
                  <h3 className="leading-[33px] sm:text-2xl text-lg text-primary_green mb-2 font-medium">
                    {item.title}
                  </h3>
                )}
                <p
                  className={`sm:text-lg text-secondaryBlack leading-[33px] ${
                    item.secondaryDesc ? "mb-0" : "mb-5"
                  }`}
                >
                  {item.desc}
                </p>
                {item.secondaryDesc && (
                  <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                    {item.secondaryDesc}
                  </p>
                )}
                {item.heading && (
                  <h3 className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                    {item.heading}
                  </h3>
                )}
                {item.ternaryDesc && (
                  <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                    {item.ternaryDesc}
                  </p>
                )}
              </div>
            ))
          : null}

        {articleId === 4
          ? blog4Items.map((item, idx) => (
              <div key={idx}>
                {item.title && (
                  <h3 className="leading-[33px] sm:text-2xl text-lg text-primary_green mb-2 font-medium">
                    {item.title}
                  </h3>
                )}
                <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                  {item.desc}
                </p>
                {item.secondaryDesc && (
                  <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                    {item.secondaryDesc}
                  </p>
                )}
                {item.heading && (
                  <h3 className="sm:text-2xl text-secondaryBlack leading-[33px] mb-1 font-medium">
                    {item.heading}
                  </h3>
                )}
                {item.ternaryDesc && (
                  <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                    {item.ternaryDesc}
                  </p>
                )}
              </div>
            ))
          : null}

        {articleId === 1
          ? blog1Items.map((item, idx) => (
              <div key={idx}>
                {item.title && (
                  <h3 className="leading-[33px] sm:text-2xl text-lg text-primary_green mb-2 font-medium">
                    {item.title}
                  </h3>
                )}
                <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                  {item.desc}
                </p>
                {item.secondaryDesc && (
                  <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                    {item.secondaryDesc}
                  </p>
                )}
                {item.heading && (
                  <h3 className="sm:text-2xl text-secondaryBlack font-medium leading-[33px] mb-5">
                    {item.heading}
                  </h3>
                )}
                {item.ternaryDesc && (
                  <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                    {item.ternaryDesc}
                  </p>
                )}
              </div>
            ))
          : null}

        {articleId === 3
          ? blog3Items.map((item, idx) => (
              <div className="ms-5" key={idx}>
                {item.title && (
                  <h3 className="leading-[33px] sm:text-2xl text-lg text-primary_green mb-2 font-medium relative">
                    {idx !== blog3Items.length - 1 && (
                      <span className="w-1.5 h-1.5 absolute left-0 top-[40%] translate-x-[-40%] bg-secondaryBlack rounded-full"></span>
                    )}
                    <span
                      className={`${
                        idx !== blog3Items.length - 1 ? "ms-5" : ""
                      }`}
                    >
                      {item.title}
                    </span>
                  </h3>
                )}
                <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                  {item.desc}
                </p>
              </div>
            ))
          : null}
      </div>
    </section>
  );
};

export default ArticleBody;
